// extracted by mini-css-extract-plugin
export var servicesBusinessSection = "m_bd";
export var servicesCardBorderSection = "m_bc";
export var servicesCasesSection = "m_bm";
export var servicesCompanyInfoSection = "m_bk";
export var servicesDevExpertiseSection = "m_bh";
export var servicesIndustriesSection = "m_bf";
export var servicesProcessSection = "m_bg";
export var servicesProjLogoSection = "m_bl";
export var servicesQuotesSection = "m_bn";
export var servicesTechStackSection = "m_bj";